:root {
    --grey-1:#C8C8D0;
    --grey-2:#8F919B;
    --grey-mid:#E7EAF0;
    --grey-light:#F0F4F9;
    --grey-hover: #A5A7AF;
    --orange:#D25C4E;
    --error :#E84353;
    --black-text:#16191F;

    --action-shadow:rgba(174, 141, 120, 0.4);
    --error-shadow:rgba(232, 67, 83, 0.4);

    //light theme
    --white: #FFFFFF;
    --light-2:#DEDCD4;
    --light-3: #0A1D42;
    --light-4:#4F5A6C;
    --secondary:#3A4965;
    --action:  #AE8D78;
    --action-light:#BEA493;


    //fonts
    --montserrat: 'Montserrat';
    --miller :'Miller Display';

    //size
    --text-xxs:12px;
    --text-xs:14px;
    --text-sm:16px;
    --text-md:18px;
    --text-lg:20px;
    --text-xl:22px;
    --text-xxl:24px;
    --text-xxxl:36px;
    --text-xxxxl:48px;   
    --text-70:70px;   
  }