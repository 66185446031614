@import './styles/css-variables.scss';
@font-face {
  font-family: "Miller Family";
  src: local("Miller Family"),
   url("./fonts/Miller.otf") format("truetype");
  }

  body{
    font-size: var(--text-sm);
    font-family: var(--miller);
  }

h1,h2,h3,h4,h5,h5,p{
  margin: 0;
}
  //headings

  h1{
    font-family: var(--miller);
    font-weight: 400;
    font-size: var(--text-xxxxl);
    line-height: 50px;
  }

  h2{
    font-family: var(--miller);
    font-weight: 400;
    font-size: var(--text-xxxl);
    line-height: 43px;
  }

  h3{
    font-family: var(--miller);
              font-weight: 600;
              font-size: var(--text-xxl);
              line-height: 29px;
  }

  h4{
    font-family: var(--miller);
    font-weight: 400;
    font-size: var(--text-lg);
    line-height: 24px;
  }

  h5{
    font-family: var(--miller);
    font-weight: 700;
    font-size: var(--text-sm);
    line-height: 19px;
  }

  h6{
    font-family: var(--miller);
    font-weight: 700;
    font-size: var(--text-xs);
    line-height: 17px;
  }

  small{
    font-family: var(--miller);
    font-weight: 400;
    font-size: var(--text-xxs);
    line-height: 14px;
  }
